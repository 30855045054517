<template>
  <v-container class="crud-content" fill-height>
    <v-row class="title">
      <v-btn icon color="primary" large @click.prevent.stop="cancelForm()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <h3>Detalhamento da Fatura</h3>
      <v-spacer />
      <v-menu offset-y transition="slide-x-transition">
        <template v-slot:activator="{ attrs, on }">
          <v-btn key="Baixar" class="custom-button--light" v-bind="attrs" v-on="on">
            <v-icon class="mr-3">mdi-download</v-icon>
            Baixar
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, index) in items" :key="index" @click="onDropwdownClickHandler(item)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <v-row>
      <v-col class="p3 color--grey-0">
        Até o dia <span class="font-weight-bold">{{ form.finalDate }}</span> suas vendas serão lançadas nesta fatura.
      </v-col>
    </v-row>
    <v-row v-if="this.form.provisionItemsIndicator && this.form.provisionItemsIndicator !== 'NONE'">
      <v-col class="p3 color--red-0">
        Observação: Esta fatura possui ainda pagamentos provisionados aguardando o período de carência e entregas para o fechamento da fatura. Os
        valores estão sujeitos à alteração até o fechamento da fatura.
      </v-col>
    </v-row>

    <v-row>
      <v-col xl="3" lg="4" md="6" sm="12" class="mt-5">
        <v-row>
          ID da fatura
        </v-row>
        <v-row class="readonly-label">
          {{ form.id }}
        </v-row>
      </v-col>
      <v-col xl="3" lg="4" md="6" sm="12" class="mt-5">
        <v-row>
          Período do faturamento
        </v-row>
        <v-row class="readonly-label">
          {{ form.period }}
        </v-row>
      </v-col>
      <v-col xl="6" lg="4" md="12" sm="12" class="mt-3">
        <v-row>
          Nome do seller
        </v-row>
        <v-row class="readonly-label">
          {{ form.sellerName }}
        </v-row>
      </v-col>
      <v-col xl="3" lg="4" md="6" sm="12" class="mt-5">
        <v-row>
          Data de fechamento
        </v-row>
        <v-row class="readonly-label">
          {{ form.closeDate }}
        </v-row>
      </v-col>
      <v-col xl="3" lg="4" md="6" sm="12" class="mt-5">
        <v-row>
          Data de pagamento
        </v-row>
        <v-row class="readonly-label">
          {{ form.paymentDate }}
        </v-row>
      </v-col>
      <v-col xl="3" lg="4" md="6" sm="12" class="mt-5">
        <v-row>
          Valor total da fatura
        </v-row>
        <v-row class="readonly-label color--blue-0">
          {{ form.total }}
        </v-row>
      </v-col>
      <v-col xl="3" lg="4" md="6" sm="12" class="mt-5">
        <v-row>
          Situação
        </v-row>
        <v-row class="readonly-label">
          <div class="camelcase" :class="getColorStatus(form.status)">{{ form.status }}</div>
        </v-row>
      </v-col>
      <v-col xl="6" lg="8" md="12" sm="12" v-if="isClosed" class="mt-5">
        <v-row>
          Fechada por
        </v-row>
        <v-row class="readonly-label">
          {{ form.userFullName }}
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="subtitle">
        Composição do valor da fatura
      </v-col>
    </v-row>
    <v-row>
      <v-col class="composition-container">
        <v-row class="composition-description">
          <v-col>
            Valor total de produtos
          </v-col>
          <v-col class="composition--gray" cols="auto">
            {{ form.grossValueTotal }}
          </v-col>
        </v-row>
        <v-row class="composition-description">
          <v-col>
            Valor total de frete
          </v-col>
          <v-col class="composition--gray" cols="auto">
            {{ form.shippingValueTotal }}
          </v-col>
        </v-row>
        <v-row class="composition-description">
          <v-col>
            Valor total de acréscimo
          </v-col>
          <v-col class="composition--gray" cols="auto">
            {{ form.interestValue }}
          </v-col>
        </v-row>
        <v-row class="composition-description">
          <v-col>
            Valor total bruto de comissões
          </v-col>
          <v-col class="composition--gray" cols="auto">
            {{ form.commissionValueTotal }}
          </v-col>
        </v-row>
        <v-row class="composition-description" v-if="form.provisionItemsIndicator !== 'NONE'">
          <v-col>
            Valor total de pagamentos provisionados
          </v-col>
          <v-col class="composition--gray" cols="auto">
            {{ form.provisionedTotal }}
            <v-icon x-large color="red">mdi-alert-outline</v-icon>
          </v-col>
        </v-row>
        <v-row class="composition-description">
          <v-col>
            Valor total de estornos
          </v-col>
          <v-col class="composition--gray" cols="auto">
            {{ form.chargeBackTotal }}
          </v-col>
        </v-row>
        <v-row class="composition-description">
          <v-col>
            Valor líquido total de pagamentos
          </v-col>
          <v-col class="composition--green" cols="auto">
            {{ form.paymentValueTotal }}
          </v-col>
        </v-row>
        <v-row class="composition-total mt-3">
          <v-col>
            Valor total da fatura
          </v-col>
          <v-col class="composition--blue" cols="auto">
            {{ form.total }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class=" mt-10">
      <v-btn :disabled="!canClose" v-if="admin" color="primary" class="ml-auto" @click="openCloseModal()">
        <v-icon class="mr-3">mdi-calendar</v-icon>
        Fechar fatura
      </v-btn>
    </v-row>

    <v-row>
      <v-col class="subtitle">
        Lançamentos
      </v-col>
    </v-row>

    <v-row>
      <v-data-table
        :headers="header"
        :items="form.items"
        class="elevation-1 custom-table"
        :options.sync="options"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        @update:sort-by="updateSortBy"
        @update:sort-desc="updateSortDesc"
        :footer-props="{
          'items-length': 100,
          'items-per-page-text': 'Itens por página',
          'items-per-page-options': [10, 20, 50],
          pageText: '{0}-{1} de {2}',
        }"
      >
        <template v-slot:[`item.dateAndHourFormat`]="{ item }">
          {{ item.dateAndHour }}
        </template>
        <template v-slot:[`item.description`]="{ item }">
          {{ item.description }} <span class="color--red-0" v-if="item.status == 'PROVISIONADO'"> (Provisionado) </span>
        </template>
        <template v-slot:[`item.order`]="{ item }">
          <a v-if="item.order" @click.prevent="openOrder(item.order)">{{ item.order }}</a>
        </template>
        <template v-slot:[`item.netValue`]="{ item }">
          <div :class="item.commissionValue ? 'color--green-0' : ''">{{ item.netValue }}</div>
        </template>
        <template v-slot:[`item.statusIcon`]="{ item }">
          <v-icon class="color--red-0" v-if="item.status == 'PROVISIONADO'">mdi-alert-outline</v-icon>
        </template>
        <template v-slot:no-data>
          <div class="pt-10 pb-10">
            Nenhum resultado encontrado
          </div>
        </template>
      </v-data-table>
    </v-row>
    <v-row>
      <v-col cols="12">
        <AlertModal :showModal="showModal">
          <template v-slot:header>
            <div class="d-flex flex-column">
              Atenção!
            </div>
          </template>
          <template v-slot:content>
            <div class="d-flex flex-column">
              <div class="mb-3">
                Após o fechamento, esta fatura não poderá mais ser reaberta. Também não será permitida a entrada de novos lançamentos manuais e/ou
                automáticos.
              </div>
              <div class="mb-3">Caso existam lançamentos provisionados, os mesmos serão inseridos na próxima fatura.</div>
              <div>Deseja mesmo realizar o fechamento da fatura?</div>
            </div>
          </template>
          <template v-slot:footer>
            <v-btn outlined color="primary" class="mr-3" @click="closeModal()">CANCELAR</v-btn>
            <v-btn color="primary" @click="closeInvoiceRequest()">SIM, FECHAR FATURA</v-btn>
          </template>
        </AlertModal>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import moment from 'moment';
import { routerBack, routerPush } from '@/service/kplaceRouter';
import notification from '@/service/notification';
import getColorStatus, { statusEnum } from '../util/status';
import isAdmin from '@/utils/user-utils';
import getInvoice, { closeInvoice, getReport } from '@/service/invoice/invoice-service';
import AlertModal from '@/components/modal/AlertModal';

export default {
  components: {
    AlertModal,
  },
  data() {
    return {
      items: [{ title: 'CSV' }, { title: 'PDF' }],
      form: {},
      showModal: false,
      admin: isAdmin,
      options: {},
      header: [
        { text: 'Id de lançamento', value: 'id' },
        { text: 'Data e horário', value: 'dateAndHourFormat' },
        { text: 'Pedido', value: 'order' },
        { text: 'Tipo', value: 'type' },
        { text: 'Descrição', value: 'description' },
        { text: 'Valor Produto', value: 'productValue' },
        { text: 'Valor Acréscimo', value: 'interestValue' },
        { text: 'Valor Frete', value: 'shippingValue' },
        { text: 'Valor comissão', value: 'commissionValue' },
        { text: 'Valor líquido', value: 'netValue' },
        { text: '', value: 'statusIcon' },
      ],
      sortBy: 'dateAndHourFormat',
      sortDesc: true,
    };
  },
  async mounted() {
    await this.init();
  },
  computed: {
    canClose() {
      return this.form.status === 'AGUARDANDO FECHAMENTO' && isAdmin();
    },
    isClosed() {
      return this.form.status === statusEnum.FECHADA;
    },
  },
  methods: {
    getColorStatus,
    async closeInvoiceRequest() {
      try {
        const response = await closeInvoice(this.$route.params.id);
        if (response.status === 204) {
          notification('Fatura fechada com sucesso.', 'success');
          this.cancelForm();
        } else {
          notification(`Não foi possível fechar a fatura: ${response.response.data.message}`, 'error');
        }
      } catch (error) {
        notification('Erro ao fechar fatura', 'error');
      }
    },
    closeModal() {
      this.showModal = false;
    },
    openCloseModal() {
      this.showModal = true;
    },
    exitForm() {
      this.backDialogOpen = false;
      routerBack();
    },
    cancelForm() {
      this.exitForm();
      this.$emit('formCancel');
    },
    init() {
      try {
        this.loading = true;
        this.getDataInvoice();
        this.showModal = false;
      } catch (e) {
        notification(`Erro ao realizar busca das faturas : ${e}`, 'error');
      } finally {
        this.loading = false;
      }
    },
    async getDataInvoice() {
      this.form = await getInvoice(this.$route.params.id);
      this.form.paymentDate = this.formattedDate(this.form.paymentDate, 'DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY');
      if (this.form.ableToClosePeriod) this.form.status = statusEnum.AGUARDANDO_FECHAMENTO;
    },
    async getReport(name, type) {
      const data = {
        name,
        content: JSON.stringify(this.form),
      };
      const response = await getReport(data, type);
      return response;
    },
    openOrder(id) {
      routerPush(`/order/detail/${id}`);
    },
    async onDropwdownClickHandler(item) {
      const type = item.title.toLowerCase();
      const response = await this.getReport('invoice-detailing', type);
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `invoiceDetailing.${type}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    },
    formattedDate(dateStr, currentPattern, expectedPattern) {
      if (dateStr) {
        const date = moment(dateStr, currentPattern);
        return date.format(expectedPattern);
      }
      return null;
    },
    updateSortBy(column) {
      this.sortBy = column;
    },
    updateSortDesc(value) {
      this.sortDesc = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.crud-content {
  background-color: #f8f8f8;
  height: calc(100% - 50px);
  width: 100%;
  margin-top: 126px;
  border-radius: 15px;
  padding: 40px 60px 60px 60px;
}

.readonly-label {
  background: #ffffff;
  width: 100%;
  height: 52px;
  align-self: center;
  align-items: center;
  padding-left: 10px;
  color: var(--grey-0);
  font-size: 14px;
}

.status {
  &--aguardando {
    color: var(--orange-0);
  }
  &--paga {
    color: var(--green-1);
  }
}

.composition-container {
  box-shadow: 3px 3px 20px var(--grey-2);
}

.composition {
  &-description {
    background: var(--background-2);
    border-bottom: 1px solid var(--grey-3);
    font-size: 14px;
    align-items: center;
  }
  &-total {
    background: var(--background-2);
    border-bottom: 1px solid var(--grey-3);
    font-size: 18px;
    align-items: center;
  }
}

.composition {
  &--green {
    color: var(--green-0);
    font-size: 22px;
  }
  &--gray {
    color: var(--grey-1);
    font-size: 22px;
  }
  &--blue {
    font-size: 28px;
    color: var(--blue-0);
  }
}

.title {
  align-items: center;
  font-size: 22px;
}

.subtitle {
  color: var(--grey-0);
  font-size: 18px;
}

.alert-card {
  width: 320px;
}
</style>
